<template>
  <Layout
    :title="
      success ? $t('waterMark.detectTitle') : $t('waterMark.failDetectTitle')
    "
  >
    <div :class="prefixCls">
      <!--   鉴定结果   -->
      <div :class="prefixCls + '__result'" v-if="success && data.url">
        <img :src="data.url" alt="" />
      </div>
      <div :class="prefixCls + '__err'" v-else-if="success && data.url">
        <img :src="data.url" alt="" />
      </div>
      <!--    无结果    -->
      <div :class="prefixCls + '__empty'" v-else>
        <h2>{{ $t('waterMark.failReason') }}</h2>
        <h5>{{ $t('waterMark.possibleReason') }}</h5>
        <p>{{ $t('waterMark.reason1') }}</p>
        <p>{{ $t('waterMark.reason2') }}</p>
      </div>
      <div :class="prefixCls + '__footer'">
        <HaiButton @click="$router.back()" v-if="!success">{{
          $t('tryAgain')
        }}</HaiButton>
        <HaiButton @click="$router.push({ path: '/' })">{{
          $t('backHome')
        }}</HaiButton>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/components/Layout'
import HaiButton from '@/components/HaiButton'

const prefixCls = 'watermark-detection-result-page'
export default {
  name: 'WatermarkDetectionResult',
  components: {
    Layout,
    HaiButton
  },

  data() {
    return {
      prefixCls,
      success: false,
      data: {
        url: ''
      }
    }
  },

  mounted() {
    try {
      const data = JSON.parse(
        window.sessionStorage.getItem('watermark_detection')
      )
      if (data.url) {
        this.data = data
        this.success = true
      }
    } catch (e) {
      this.success = false
    }
  },

  methods: {}
}
</script>

<style lang="scss">
$prefixCls: 'watermark-detection-result-page';
.#{$prefixCls} {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  &__result,
  &__err {
    width: 380px;
    height: 380px;
    background-image: url('../assets/images/border-err@2x.png');
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 90%;
      height: 90%;
    }
  }
  &__empty {
    width: 600px;
    margin-top: 170px;
    padding: 20px;
    color: #68e7ed;
    background-color: #143045;
    border-top: 1px #fff solid;
    h2 {
      font-size: 24px;
      text-align: center;
      margin-bottom: 20px;
    }
    p,
    h5 {
      font-size: 16px;
      text-align: left;
      line-height: 32px;
    }
  }
  &__footer {
    margin-bottom: 70px;
    .hai-button:nth-child(1) {
      margin-right: 28px;
      span {
        color: #fff;
      }
    }
  }
}
</style>
